import BarChart from "@/components/BarChart.vue";
import Container from "@/components/Container.vue";
import EmptyState from "@/components/EmptyState.vue";
import EditorInput from "@/components/FormComponents/EditorInput.vue";
import FormButton from "@/components/FormComponents/FormButton.vue";
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import TextAreaInput from "@/components/FormComponents/TextAreaInput.vue";
import TextInput from "@/components/FormComponents/TextInput.vue";
import SelectInput from "@/components/FormComponents/SelectInput.vue";
import Header from "@/components/Header.vue";
import ImageCropper from "@/components/ImageCropper/ImageCropper.vue";
import LoadingContainer from "@/components/LoadingContainer.vue";
import ModalComponent from "@/components/ModalComponent";
import Navbar from "@/components/Navbar.vue";
import SearchBar from "@/components/SearchBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import Slider from "@/components/Slider.vue";
import TableHead from "@/components/TableHead.vue";
import UserService from "@/services/UserService";
import { EventBus } from "@/utils/EventBus";
import * as helper from "@/utils/Helpers";
import * as helperVariables from "@/utils/HelperVariables";
import "@fortawesome/fontawesome-free/css/all.css";
import "@popperjs/core";
import "bootstrap";
import "jquery";
import * as moment from "moment";
import "moment/locale/pt-br";
import "quill/dist/quill.snow.css";
import VueMask from "v-mask";
import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./assets/theme/scss/theme.scss";
import router from "./router";
import paths from "./router/paths";
import ContentsService from "./services/ContentsService";
import MedicalRecordsService from "./services/MedicalRecordsService";
import PrescriptionsService from "./services/PrescriptionsService";
import store from "./store";
import "./styles.scss";
import pdf from "vue-pdf";
import PatientsService from "./services/PatientsService";
import MedicinesService from "./services/MedicinesService";
import ConfirmationAlert from "./components/ConfirmationAlert/ConfirmationAlert.vue";
import draggable from "vuedraggable";
import "./utils/Prototypes/prototypeFunctions";
import AIFilesService from "./services/AIFilesService";
import FileUploader from "./components/FileUploader/FileUploader.vue";
import IncrementTextInput from "./components/FormComponents/IncrementTextInput.vue";

Vue.use(VueQuillEditor);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.config.productionTip = false;

//services
Vue.prototype.$helper = helper;
Vue.prototype.$helperVariables = helperVariables;
Vue.prototype.$userService = new UserService();
Vue.prototype.$prescriptionsService = new PrescriptionsService();
Vue.prototype.$medicalRecordsService = new MedicalRecordsService();
Vue.prototype.$contentsService = new ContentsService();
Vue.prototype.$patientsService = new PatientsService();
Vue.prototype.$medicinesService = new MedicinesService();
Vue.prototype.$aiFilesService = new AIFilesService();

//components
Vue.component("Slider", Slider);
Vue.component("Sidebar", Sidebar);
Vue.component("Navbar", Navbar);
Vue.component("LoadingContainer", LoadingContainer);
Vue.component("EmptyState", EmptyState);
Vue.component("Container", Container);
Vue.component("SearchBar", SearchBar);
Vue.component("TextInput", TextInput);
Vue.component("SelectInput", SelectInput);
Vue.component("BarChart", BarChart);
Vue.component("TextAreaInput", TextAreaInput);
Vue.component("PasswordInput", PasswordInput);
Vue.component("EditorInput", EditorInput);
Vue.component("FormButton", FormButton);
Vue.component("TableHead", TableHead);
Vue.component("ImageCropper", ImageCropper);
Vue.component("ModalComponent", ModalComponent);
Vue.component("Header", Header);
Vue.component("pdf", pdf);
Vue.component("ConfirmationAlert", ConfirmationAlert);
Vue.component("draggable", draggable);
Vue.component("FileUploader", FileUploader);
Vue.component("IncrementTextInput", IncrementTextInput);

const pkg = require("../package.json");

//utils
Vue.prototype.$paths = paths;
Vue.prototype.$eventBus = EventBus;
Vue.prototype.$moment = moment;
Vue.prototype.$envString = `${pkg.version} - ${process.env.VUE_APP_ENV_NAME}`;
// Vue.prototype.$envString = process.env.VUE_APP_ENV_STRING;
Vue.prototype.$baseImageUrl = process.env.VUE_APP_IMG_BASE_URL;

Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("currency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatter.format(value);
});

function limitStringPrototype(limit, str) {
  try {
    if (!str) {
      str = this;
    }
    return str.length >= limit ? `${str.substr(0, limit - 1)}...` : str;
  } catch (e) {
    return str;
  }
}

String.prototype.limitString = limitStringPrototype;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
