import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      filters: {
        page: 1,
        q: "",
        type: "student,professional",
      },
      columns: [
        {
          name: "Nome",
          sortName: null,
          order: null,
        },
        {
          name: "Email",
          sortName: null,
          order: null,
        },
        {
          name: "Tipo",
          sortName: null,
          order: null,
        },
        {
          name: "Tipo de profissional",
          sortName: null,
          order: null,
        },
        {
          name: "Assinante",
          sortName: null,
          order: null,
        },
        {
          name: "Data de criação",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
    handleSubscriberFilter(event) {
      if (event.target.checked) {
        this.filters.subscriber = event.target.checked;
      } else {
        delete this.filters.subscriber;
      }
      this.loadingItems = true;
      this.getData();
    },
  },
};
