import UserService from "../../../services/UserService";

export default {
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  created() {
    window.addEventListener("resize", this.calculateWidth);
  },
  data() {
    return {
      item: {},
      id: this.$route.params.id,
      loadingItems: false,
      isCancelable: false,
      addressColumns: [
        {
          name: "Nome do local",
          sortName: null,
          order: null,
        },
        {
          name: "Endereço",
          sortName: null,
          order: null,
        },
        {
          name: "Complemento",
          sortName: null,
          order: null,
        },
      ],
      columns: [
        {
          name: "Paciente",
          sortName: null,
          order: null,
        },
        {
          name: "Assinatura digital",
          sortName: null,
          order: null,
        },
        {
          name: "Data",
          sortName: null,
          order: null,
        },
        {
          name: "PDF",
          sortName: null,
          order: null,
        },
      ],
    };
  },
  methods: {
    async getData() {
      try {
        let filters = {
          user_id: this.id,
        };
        const res = await this.$userService.show(this.id, {
          address: true,
          device_information: true,
        });
        const medicalRecords = await this.$medicalRecordsService.list(filters);
        const prescriptions = await this.$prescriptionsService.list(filters);

        let data = res.data;

        data.medicalRecords = medicalRecords.data;
        data.prescriptions = prescriptions.data;

        this.$set(this, "item", data);
      } catch (e) {
        console.log(e);
      } finally {
        this.loadingItems = false;
      }
    },
    // setupSubscriptionConfig(data) {
    //   let config;
    //   if (
    //     data.stripe_id &&
    //     data.subs &&
    //     (data.subs?.stripe_status == "trialing" ||
    //       data.subs?.stripe_status == "active")
    //   ) {
    //     this.$set(this, "isPremium", true);

    //     config = [
    //       {
    //         key: "Status",
    //         value: "Assinante",
    //       },
    //     ];

    //     let plan = data.subs;
    //     let status = plan?.stripe_status;
    //     let trialEndsAt = plan?.trial_ends_at
    //       ? this.$moment(plan?.trial_ends_at).format("DD[ de ]MMMM[ de ]YYYY")
    //       : null;
    //     let endsAt = plan?.ends_at
    //       ? this.$moment(plan?.ends_at).format("DD[ de ]MMMM[ de ]YYYY")
    //       : null;
    //     let daysLeft = plan?.trial_ends_at
    //       ? Math.round(
    //           this.$moment(plan?.trial_ends_at).diff(
    //             this.$moment(),
    //             "days",
    //             true
    //           )
    //         )
    //       : null;

    //     if (endsAt) {
    //       config[0].endsAt = true;
    //       config[0].label = `A assinatura será encerrada em breve`;
    //       config[0].sublabel = `O usuário solicitou o cancelamento da assinatura e tem até o dia ${endsAt} para usufruir das funcionalidades como assinante.`;
    //     } else if (status == "trialing") {
    //       config[0].trial = true;
    //       config[0].label = `O usuário tem ${daysLeft} dias grátis para cancelar`;
    //       config[0].sublabel = `O usuário tem até o dia ${trialEndsAt} para cancelar gratuitamente seu plano.`;
    //     }

    //     config[0].value = this.$userService.getStatus(status);

    //     config = [
    //       ...config,
    //       {
    //         key: "Data",
    //         value: this.$moment(plan?.created_at).format(
    //           "DD[ de ]MMMM[ de ]YYYY"
    //         ),
    //       },
    //       // {
    //       //     key: 'Valor',
    //       //     value: (plan?.price?.unit_amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    //       // },
    //       // {
    //       //     key: 'Período',
    //       //     value: plan?.price?.recurring?.interval == 'month' ? 'Mensal' : 'Anual',
    //       // },
    //     ];

    //     if (
    //       data.subs?.stripe_price ==
    //       process.env.VUE_APP_BACKOFFICE_STRIPE_PRICE_ID
    //     ) {
    //       config[0].value = "Assinante pelo Admin";
    //       this.$set(this, "isCancelable", !endsAt);
    //     }
    //   }
    //   return config;
    // },
    openPDF(item) {
      if (item.pdf_url) {
        window.open(
          `${process.env.VUE_APP_IMG_BASE_URL}${item.pdf_url}`,
          "_blank"
        );
      }
    },
    async handlePremium() {
      this.$eventBus.$emit("showConfirmationAlert", {
        title: this.item.isPremium ? "Remover Premium" : "Tornar Premium",
        message: this.item.isPremium
          ? "Deseja remover a assinatura premium para este usuário?"
          : "Deseja dar uma assinatura premium para este usuário?",
        confirmCallback: async () => {
          try {
            this.loadingItems = true;
            if (this.item.isPremium) {
              await this.$userService.unsubscribeUser({ user_id: this.id });
              this.$eventBus.$emit("showToast", {
                title: "Assinatura removida",
                msg: `Assinatura removida com sucesso`,
              });
            } else {
              await this.$userService.subscribeUser({ user_id: this.id });
              this.$eventBus.$emit("showToast", {
                title: "Assinatura efetuada",
                msg: `Assinatura efetuada com sucesso`,
              });
            }
            this.getData();
          } catch (e) {
            console.log(e);
          }
        },
      });
    },
  },
};
