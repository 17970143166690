<template>
  <div id="users_detail">
    <Navbar
      current="Detalhe do Usuário"
      :link="$paths.users.list"
      :linkName="'Usuários'"
    />
    <Sidebar />
    <Container>
      <Header title="Detalhe do Usuário">
        <template v-slot:button v-if="!loadingItems">
          <button
            class="btn btn-outline-primary"
            @click="handlePremium"
            v-if="!item.isPremium"
          >
            <i class="fa-solid fa-crown"></i>
            Tornar Premium
          </button>
          <button
            class="btn btn-outline-danger"
            @click="handlePremium"
            v-if="item.isPremium && item.subscriptionConfig.isCancelable"
          >
            <i class="fa-solid fa-crown"></i>
            Remover Premium
          </button>
        </template>
      </Header>

      <div class="card">
        <LoadingContainer v-if="loadingItems" />
        <template v-else>
          <div class="card-header">
            <h2 class="text-header mb-0">Informações do usuário</h2>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Nome" :value="item.name" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput :readOnly="true" label="Email" :value="item.email" />
              </div>
              <div class="col-12 col-xl-4">
                <TextInput
                  :readOnly="true"
                  label="Telefone"
                  :value="item.formattedPhone"
                />
              </div>

              <div
                class="col-12"
                :class="{
                  'col-xl-4':
                    item.type == 'professional' &&
                    item.professional_type != 'other',
                  'col-xl-6':
                    item.type == 'professional' &&
                    item.professional_type == 'other',
                }"
              >
                <TextInput
                  :readOnly="true"
                  label="Tipo"
                  :value="item.account_type"
                />
              </div>
              <div
                v-if="item.type == 'professional'"
                class="col-12"
                :class="{
                  'col-xl-4':
                    item.type == 'professional' &&
                    item.professional_type != 'other',
                  'col-xl-6':
                    item.type == 'professional' &&
                    item.professional_type == 'other',
                }"
              >
                <TextInput
                  :readOnly="true"
                  label="Tipo de profissional"
                  :value="item.professionalType"
                />
              </div>
              <div class="col-12 col-xl-4" v-if="item.type == 'professional'">
                <TextInput
                  v-if="item.crm"
                  :readOnly="true"
                  label="CRM"
                  :value="item.crm"
                />
                <TextInput
                  v-if="item.cro"
                  :readOnly="true"
                  label="CRO"
                  :value="item.cro"
                />
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="card" v-if="item.device_information">
        <div class="card-header">
          <h2 class="text-header mb-0">Sobre o aparelho</h2>
        </div>
        <div class="card-body">
          <div class="list-group mb-4">
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-start">
                  <p class="text-muted mb-0">Sistema operacional</p>
                </div>
                <div class="col-auto">
                  <p class="fw-bold mb-0">
                    {{ item?.device_information?.os.capitalizeFirst() }}
                  </p>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-start">
                  <p class="text-muted mb-0">Versão</p>
                </div>
                <div class="col-auto">
                  <p class="fw-bold mb-0">
                    {{ item?.device_information?.os_version }}
                  </p>
                </div>
              </div>
            </div>
            <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-start">
                  <p class="text-muted mb-0">Modelo do aparelho</p>
                </div>
                <div class="col-auto">
                  <p class="fw-bold mb-0">
                    {{ item?.device_information?.model }}
                  </p>
                </div>
              </div>
            </div>
            <!-- <div class="list-group-item">
              <div class="row align-items-center">
                <div class="col text-start">
                  <p class="text-muted mb-0">Versão do Psiapp</p>
                </div>
                <div class="col-auto"><p class="fw-bold mb-0"></p></div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="card" v-if="item?.subscriptionConfig">
        <div class="card-header">
          <h2 class="text-header mb-0">Assinatura</h2>
        </div>
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <ul class="list-group list-group-flush">
                  <li
                    v-for="(subs, index) in item.subscriptionConfig
                      .subs_details"
                    :key="index"
                    class="list-group-item px-0"
                  >
                    <div
                      class="d-flex justify-content-between"
                      :class="{ 'mb-2': subs.label }"
                    >
                      <span class="text-paragraph">
                        {{ subs.key }}
                      </span>
                      <span class="text-subtitle">{{ subs.value }}</span>
                    </div>

                    <template v-if="subs.label">
                      <p
                        class="text-subtitle mb-2"
                        :class="{
                          'text-danger': subs.endsAt,
                          'text-primary': subs.trial,
                        }"
                      >
                        {{ subs.label }}
                      </p>
                      <p
                        class="text-paragraph mb-2"
                        :class="{
                          'text-danger': subs.endsAt,
                          'text-primary': subs.trial,
                        }"
                      >
                        {{ subs.sublabel }}
                      </p>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">
          <h2 class="text-header mb-0">Informações de endereço</h2>
        </div>
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <div
            class="table-responsive"
            v-if="item.address && item.address.length"
          >
            <table class="table table-sm table-nowrap card-table table-hover">
              <TableHead :columns="addressColumns" />
              <tbody class="list">
                <tr
                  v-for="(address, index) in item.address"
                  v-bind:key="'tr-' + index"
                >
                  <td>{{ address.name }}</td>
                  <td>
                    {{ address.address }}, {{ address.number }} -
                    {{ address.neighborhood }}. CEP: {{ address.zip_code }}.
                    {{ address.city }} - {{ address.state }}
                  </td>
                  <td class="text-end">
                    {{ address.complement }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <EmptyState
            v-else
            icon="fa-solid fa-location-dot"
            title="Nenhum endereço cadastrado"
            :noMsg="true"
          />
        </div>
      </div>

      <div v-if="item.type == 'professional'">
        <div class="card">
          <div class="card-header">
            <h2 class="text-header mb-0">Prontuário</h2>
          </div>
          <LoadingContainer v-if="loadingItems" />
          <div v-else>
            <div
              class="table-responsive"
              v-if="item.medicalRecords && item.medicalRecords.length"
            >
              <table class="table table-sm table-nowrap card-table table-hover">
                <TableHead :columns="columns" />
                <tbody class="list">
                  <tr
                    v-for="(item, index) in item.medicalRecords"
                    v-bind:key="'tr-' + index"
                  >
                    <td>{{ item.patient.name }}</td>
                    <td>{{ item.digital ? "Sim" : "Não" }}</td>
                    <td>
                      <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                      }}</time>
                    </td>
                    <td
                      v-if="item.pdf_url"
                      class="text-end cursor-pointer"
                      @click="openPDF(item)"
                    >
                      <i
                        class="fa-solid fa-lg fa-file-pdf text-primary"
                        data-bs-toggle="tooltip"
                        title="Ver prontuário"
                      ></i>
                    </td>
                    <td v-else></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <EmptyState
              v-else
              icon="fa-solid fa-clipboard-user"
              title="Nenhum prontuário encontrado"
              :noMsg="true"
            />
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h2 class="text-header mb-0">Receita</h2>
          </div>
          <LoadingContainer v-if="loadingItems" />
          <div v-else>
            <div
              class="table-responsive"
              v-if="item?.prescriptions && item?.prescriptions.length"
            >
              <table class="table table-sm table-nowrap card-table table-hover">
                <TableHead :columns="columns" />
                <tbody class="list">
                  <tr
                    v-for="(item, index) in item.prescriptions"
                    v-bind:key="'tr-' + index"
                  >
                    <td>{{ item.patient.name }}</td>
                    <td>{{ item.digital ? "Sim" : "Não" }}</td>
                    <td>
                      <time>{{
                        $moment(item.created_at).format("DD/MM/YYYY")
                      }}</time>
                    </td>
                    <td
                      class="text-end cursor-pointer"
                      v-if="item.pdf_url"
                      @click="openPDF(item)"
                    >
                      <i
                        class="fa-solid fa-lg fa-file-pdf text-primary"
                        data-bs-toggle="tooltip"
                        title="Ver receita"
                      ></i>
                    </td>
                    <td v-else></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <EmptyState
              v-else
              icon="fa-solid
            fa-prescription-bottle-medical"
              title="Nenhuma receita encontrada"
              :noMsg="true"
            />
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import UserDetailController from "./UserDetailController";
export default UserDetailController;
</script>
