<template>
  <div>
    <Navbar current="Feedback dos usuários" />
    <Sidebar />
    <Container col="col-12">
      <Header
        title="Feedback dos usuários"
        icon="fa-solid fa-comments"
        :count="totalItemsRegistered"
      />

      <div class="card">
        <!-- <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" /> -->
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState
            v-if="!items.length"
            :totalItemsRegistered="totalItemsRegistered"
            icon="fa-solid fa-comments"
            title="Nenhum feedback encontrado"
          >
            <template v-slot:noItemsRegistered></template>
          </EmptyState>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="d-flex p-4 border-bottom justiify-content-between"
          >
            <div style="max-width: 768px">
              <router-link
                v-if="item.user"
                :to="{ path: `${$paths.users.detail}/${item.user_id}` }"
                tag="a"
                class="text-subtitle mb-1"
              >
                {{ item.user.name }}
              </router-link>

              <p class="text-paragraph mb-0">
                {{ item.message }}
              </p>
            </div>
            <p class="ms-auto text-muted mb-0">
              {{ $moment(item.created_at).format("DD/MM/YYYY[ ]HH:mm") }}
            </p>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted"
            >mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span
          >
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-if="!loadingItems && moreItemsToLoad"
      >
        <div class="col-2">
          <FormButton
            label="Carregar mais"
            :loading="loadingMoreItems"
            @onClick="loadMore"
          />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import UsersFeedbackController from "./UsersFeedbackController";
export default UsersFeedbackController;
</script>
