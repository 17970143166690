<template>
  <nav
    class="navbar navbar-vertical fixed-start navbar-expand-md navbar-dark"
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <router-link
        tag="a"
        :to="$paths.home"
        class="navbar-brand text-start d-flex align-items-center mb-4"
      >
        <img
          class="navbar-brand-img"
          src="@/assets/img/logo_3.png"
          alt="Diag Backoffice"
          height="50"
        />
        <!-- <h1 class="text-white ms-2 mb-0">Diag</h1> -->
      </router-link>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm">
              <i class="fas fa-user-circle display-4 text-primary"></i>
              <!-- <img src="./assets/img/avatars/profiles/avatar-1.jpg" class="avatar-img rounded-circle" alt="..."> -->
            </div>
          </a>

          <!-- Menu -->
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="sidebarIcon"
          >
            <router-link
              class="dropdown-item text-dark"
              tag="a"
              :to="`${$paths.admins.detail}/${userSession?.id}`"
            >
              Minha conta
            </router-link>
            <hr class="dropdown-divider py-0" />
            <span
              @click="logout"
              class="dropdown-item text-muted cursor-pointer"
              >Sair</span
            >
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="$paths.home"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-chart-simple"></i>
              Visão geral

              <span class="badge bg-light text-dark fw-bold ms-auto">
                {{ $envString }}
              </span>
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Cadastros
        </h6>
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="$paths.users.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-user-group"></i>
              Usuários
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.patients.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-user-injured"></i>
              Pacientes
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.admins.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-user-shield"></i>
              Administradores
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Conteúdos
        </h6>
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="$paths.contents.categories.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-regular fa-file-lines"></i>
              Conteúdos
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.prescriptions.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i
                class="sidebar-icon fa-solid fa-prescription-bottle-medical"
              ></i>
              Receitas
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.medical_records.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-clipboard-user"></i>
              Prontuários
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.medicines.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-pills"></i>
              Medicamentos
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="$paths.aiFiles.list"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-brain"></i>
              Arquivos IA
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Material de apoio
        </h6>
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="{ path: $paths.calculators.list }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-calculator"></i>
              Calculadoras
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="{ path: $paths.scores.list }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-ruler-vertical"></i>
              Escores
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Aplicativo
        </h6>
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="{ path: $paths.sendNotification }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-bell"></i>
              Enviar Notificação
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="{ path: $paths.sendEmail }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-envelope"></i>
              Enviar Email
            </a>
          </router-link>
        </ul>

        <hr class="navbar-divider my-3" />
        <h6 class="navbar-heading text-muted text-label mt-2 mb-0">
          Contratos & outros
        </h6>
        <ul class="navbar-nav">
          <router-link
            active-class="router-link-active"
            :to="{ path: `${$paths.settings.edit}/termos-de-uso` }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-file-signature"></i>
              Contratos
            </a>
          </router-link>
          <router-link
            active-class="router-link-active"
            :to="{ path: $paths.usersFeedback }"
            tag="li"
            class="nav-item"
          >
            <a class="nav-link text-paragraph">
              <i class="sidebar-icon fa-solid fa-comments"></i>
              Feedback dos usuários
            </a>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  methods: {
    async logout() {
      try {
        await this.$userService.logout();
      } catch (error) {
        console.log("ERRO NO LOGOUT", error);
      } finally {
        this.$router.push(this.$paths.login);
      }
    },
  },
  computed: {
    userSession() {
      return this.$store.state.userSession;
    },
  },
};
</script>

<style scoped>
.sidebar-icon {
  width: 25px;
  text-align: left;
}
#sidebar .router-link-active .nav-link {
  font-weight: 700;
}
</style>
