import moment from "moment";

export class SubscriptionService {
  static SUBSCRIPTION_TYPE = {
    IAPP: "INAPPPURCHASE",
    STRIPE: "STRIPE",
  };

  static STATUS_LABEL = {
    active: "Assinante",
    trialing: "Assinante",
    canceled_trialing: "Assinante",
    canceled_active: "Assinante",
    incomplete: "Incompleto",
    incomplete_expired: "Expirado",
    past_due: "Vencido",
    unpaid: "Não pago",
    canceled: "Cancelado",
    paused: "Pausado",
  };

  static STATUS_COLOR = {
    active: "success",
    trialing: "success",
    canceled_trialing: "success",
    canceled_active: "success",
    incomplete: "warning",
    incomplete_expired: "danger",
    past_due: "danger",
    unpaid: "danger",
    canceled: "danger",
    paused: "info",
  };

  prepareSubscriptionForStripe = (data) => {
    let price = "";
    let interval = "";
    let isCancelable = false;

    const priceObject = data.price;

    if (priceObject) {
      price = (priceObject.unit_amount / 100).toCurrency();
      interval = priceObject.recurring.interval == "month" ? "Mensal" : "Anual";
    }

    const trial_ends_at = data?.trial_ends_at
      ? moment(data?.trial_ends_at).format("DD[ de ]MMMM[ de ]YYYY")
      : null;

    const ends_at = data?.ends_at
      ? moment(data?.ends_at).format("DD[ de ]MMMM[ de ]YYYY")
      : null;

    const days_left = data?.trial_ends_at
      ? Math.round(moment(data?.trial_ends_at).diff(moment(), "days", true))
      : null;

    const created_at = data?.created_at
      ? moment(data?.created_at).format("DD[ de ]MMMM[ de ]YYYY")
      : null;

    let status = data?.stripe_status;

    const active = status == "trialing" || status == "active";

    if (data?.stripe_status == "trialing") {
      if (data?.ends_at == data?.trial_ends_at) {
        status = "cancelled_trialing";
      }
    } else if (data?.stripe_status == "active") {
      if (data?.ends_at) {
        status = "cancelled_active";
      }
    }

    let statusLabel = SubscriptionService.STATUS_LABEL[status];
    let statusColor = SubscriptionService.STATUS_COLOR[status];

    const givenByAdmin =
      data.stripe_price == process.env.VUE_APP_BACKOFFICE_STRIPE_PRICE_ID;

    if (active && givenByAdmin) {
      statusLabel = "Assinante pelo Admin";
      statusColor = "success";
    }

    let subs_details = [
      {
        key: "Status",
        value: SubscriptionService.STATUS_LABEL[status],
      },
      {
        key: "Data",
        value: moment(data?.created_at).format("DD[ de ]MMMM[ de ]YYYY"),
      },
    ];

    if (ends_at) {
      subs_details[0].endsAt = true;
      subs_details[0].label = `A assinatura será encerrada em breve`;
      subs_details[0].sublabel = `O usuário solicitou o cancelamento da assinatura e tem até o dia ${ends_at} para usufruir das funcionalidades como assinante.`;
    } else if (status == "trialing") {
      subs_details[0].trial = true;
      subs_details[0].label = `O usuário tem ${days_left} dias grátis para cancelar`;
      subs_details[0].sublabel = `O usuário tem até o dia ${trial_ends_at} para cancelar gratuitamente seu plano.`;
    }

    if (givenByAdmin) {
      if (active) {
        subs_details[0].value = "Assinante pelo Admin";
        subs_details[0].label = "";
        subs_details[0].sublabel = "";
        isCancelable = true;
      } else {
        subs_details[0].value = "Assinante pelo Admin (Cancelada)";
        subs_details[0].label = "A assinatura pelo Admin está encerrada";
        subs_details[0].sublabel = `A assinatura pelo Admin foi encerrada no dia ${ends_at}`;
        // subs_details.splice(1, 1);
      }
    } else {
      subs_details = [
        ...subs_details,
        {
          key: "Valor",
          value: (data?.price?.unit_amount / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
        },
        {
          key: "Período",
          value:
            data?.price?.recurring?.interval == "month" ? "Mensal" : "Anual",
        },
      ];
    }

    return {
      //   originalObject: data,
      ...data,
      price,
      interval,
      trial_ends_at,
      ends_at,
      status,
      created_at,
      active,
      subscriptionType: SubscriptionService.SUBSCRIPTION_TYPE.STRIPE,
      givenByAdmin,
      statusColor,
      statusLabel,
      isCancelable,
      subs_details,
    };
  };

  prepareSubscriptionForIAP = (data) => {
    const subs_details = [
      {
        key: "Status",
        value: "Assinante InApp",
      },
      {
        key: "Data",
        value: moment(data?.created_at).format("DD[ de ]MMMM[ de ]YYYY"),
      },
      {
        key: "Valor",
        value: (data?.plan?.price).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
        }),
      },
      {
        key: "Período",
        value: "Mensal",
      },
    ];

    return {
      //   originalObject: data,
      ...data,
      price: data.plan.price.toCurrency(),
      interval: "Mensal",
      status: "active",
      created_at: moment(data.created_at).format("DD[ de ]MMMM[ de ]YYYY"),
      active: true,
      subscriptionType: SubscriptionService.SUBSCRIPTION_TYPE.IAPP,
      statusColor: "success",
      statusLabel: "Assinante InApp",
      subs_details,
    };
  };
}
