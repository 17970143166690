import ListControllerMixin from "../../utils/ListControllerMixin";

export default {
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true;
    this.getData();
  },
  data() {
    return {
      filters: {
        page: 1,
        q: "",
      },
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.listFeedback({
        ...this.filters,
        orderBy: this.columns.filter(
          (r) => r.order != null && r.sortName != null
        ),
      });
    },
  },
};
