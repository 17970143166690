import { email, minLength, required, url } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      TITLE_MAX_LENGTH: 60,
      DESCRIPTION_MAX_LENGTH: 220,
      errorMessage: "",
      submitting: false,
      form: {
        title: "",
        send_to: "all",
        description: "",
        link: "",
        emails: "",
      },
      emails: [],
      sendToOptions: [
        {
          id: "all",
          title: "Todos os usuários",
        },
        {
          id: "subscribers",
          title: "Usuários assinantes",
        },
        // {
        //   id: "no-subscribers",
        //   title: "Usuários não assinantes",
        // },
      ],
    };
  },
  validations() {
    return {
      form: {
        title: {
          required,
          minLength: minLength(5),
        },
        description: {
          required,
          minLength: minLength(5),
        },
        send_to: {
          required,
        },
        link: {
          url,
        },
        emails: {
          email,
        },
      },
    };
  },
  methods: {
    async submit() {
      try {
        this.submitting = true;
        this.errorMessage = "";

        if (this.form.emails) {
          this.emails.push(this.form.emails);
          this.form.emails = "";
        }

        const values = {
          ...this.form,
          emails: this.emails.join(","),
        };

        const res = await this.$userService.sendEmail(values);
        this.$eventBus.$emit("showToast", {
          title: "Email enviado",
          msg: res.message || `O email foi enviado aos usuários com sucesso`,
        });
        this.resetForm();
      } catch (e) {
        this.errorMessage = e.message;
      } finally {
        this.submitting = false;
      }
    },
    resetForm() {
      this.form = {
        title: "",
        description: "",
        link: "",
        emails: "",
      };
      this.emails = [];
      this.$v.$reset();
    },
    addEmail(email) {
      this.emails.push(email);
      this.form.emails = "";
    },
    removeEmail(index) {
      this.emails.splice(index, 1);
    },
  },
};
